export const CHANNEL_OPTIONS = [
    {key: 1, text: 'OpenAI', value: 1, color: 'green'},
    {key: 14, text: 'Anthropic Claude', value: 14, color: 'black'},
    {key: 33, text: 'AWS', value: 33, color: 'black'},
    {key: 3, text: 'Azure OpenAI', value: 3, color: 'olive'},
    {key: 11, text: 'Google PaLM2', value: 11, color: 'orange'},
    {key: 24, text: 'Google Gemini', value: 24, color: 'orange'},
    {key: 28, text: 'Mistral AI', value: 28, color: 'orange'},
    {key: 41, text: 'Novita', value: 41, color: 'purple'},
  {
    key: 40,
    text: '字节火山引擎',
    value: 40,
    color: 'blue',
    description: '原字节跳动豆包',
  },
  {
    key: 15,
    text: '百度文心千帆',
    value: 15,
    color: 'blue',
    tip: '请前往<a href="https://console.bce.baidu.com/qianfan/ais/console/applicationConsole/application/v1" target="_blank">此处</a>获取 AK（API Key）以及 SK（Secret Key），注意，V2 版本接口请使用 <strong>百度文心千帆 V2 </strong>渠道类型',
  },
  {
    key: 47,
    text: '百度文心千帆 V2',
    value: 47,
    color: 'blue',
    tip: '请前往<a href="https://console.bce.baidu.com/iam/#/iam/apikey/list" target="_blank">此处</a>获取 API Key，注意本渠道仅支持<a target="_blank" href="https://cloud.baidu.com/doc/WENXINWORKSHOP/s/em4tsqo3v">推理服务 V2</a>相关模型',
  },
    {
        key: 17,
        text: '阿里通义千问',
        value: 17,
        color: 'orange',
        tip: '如需使用阿里云百炼，请使用<strong>阿里云百炼</strong>渠道',
    },
    {key: 49, text: '阿里云百炼', value: 49, color: 'orange'},
  {
    key: 18,
    text: '讯飞星火认知',
    value: 18,
    color: 'blue',
    tip: '本渠道基于讯飞 WebSocket 版本 API，如需 HTTP 版本，请使用<strong>讯飞星火认知 V2</strong>渠道',
  },
  {
    key: 48,
    text: '讯飞星火认知 V2',
    value: 48,
    color: 'blue',
    tip: 'HTTP 版本的讯飞接口，前往<a href="https://console.xfyun.cn/services/cbm" target="_blank">此处</a>获取 HTTP 服务接口认证密钥',
  },
    {key: 16, text: '智谱 ChatGLM', value: 16, color: 'violet'},
    {key: 19, text: '360 智脑', value: 19, color: 'blue'},
    {key: 25, text: 'Moonshot AI', value: 25, color: 'black'},
    {key: 23, text: '腾讯混元', value: 23, color: 'teal'},
    {key: 26, text: '百川大模型', value: 26, color: 'orange'},
    {key: 27, text: 'MiniMax', value: 27, color: 'red'},
    {key: 29, text: 'Groq', value: 29, color: 'orange'},
    {key: 30, text: 'Ollama', value: 30, color: 'black'},
    {key: 31, text: '零一万物', value: 31, color: 'green'},
    {key: 32, text: '阶跃星辰', value: 32, color: 'blue'},
    {key: 34, text: 'Coze', value: 34, color: 'blue'},
    {key: 35, text: 'Cohere', value: 35, color: 'blue'},
    {key: 36, text: 'DeepSeek', value: 36, color: 'black'},
    {key: 37, text: 'Cloudflare', value: 37, color: 'orange'},
    {key: 38, text: 'DeepL', value: 38, color: 'black'},
    {key: 39, text: 'together.ai', value: 39, color: 'blue'},
    {key: 42, text: 'VertexAI', value: 42, color: 'blue'},
    {key: 43, text: 'Proxy', value: 43, color: 'blue'},
    {key: 44, text: 'SiliconFlow', value: 44, color: 'blue'},
    {key: 45, text: 'xAI', value: 45, color: 'blue'},
    {key: 46, text: 'Replicate', value: 46, color: 'blue'},
    {key: 8, text: '自定义渠道', value: 8, color: 'pink'},
    {key: 22, text: '知识库：FastGPT', value: 22, color: 'blue'},
    {key: 21, text: '知识库：AI Proxy', value: 21, color: 'purple'},
    {key: 20, text: 'OpenRouter', value: 20, color: 'black'},
    {key: 2, text: '代理：API2D', value: 2, color: 'blue'},
    {key: 5, text: '代理：OpenAI-SB', value: 5, color: 'brown'},
    {key: 7, text: '代理：OhMyGPT', value: 7, color: 'purple'},
    {key: 10, text: '代理：AI Proxy', value: 10, color: 'purple'},
    {key: 4, text: '代理：CloseAI', value: 4, color: 'teal'},
    {key: 6, text: '代理：OpenAI Max', value: 6, color: 'violet'},
    {key: 9, text: '代理：AI.LS', value: 9, color: 'yellow'},
    {key: 12, text: '代理：API2GPT', value: 12, color: 'blue'},
    {key: 13, text: '代理：AIGC2D', value: 13, color: 'purple'},
];
